<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">Explain Storage?</h2>
      <p class="desc">
        The designing and implementation of storage solutions are also done by
        Ejaf that comprises of software-defined storage, hybrid, FC SAN storage,
        all-flash array, IP Storage, Scale-out NAS, Object Storage and
        Mission-critical storage which can be combined with OS settings. We
        support our clients in their transition from their existing storage to
        modern storage platforms comfortably.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/data-center/storage/459-4594463_24-7-365-png-transparent-png-recovered.png"
        alt="Explain Storage?"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "frist-stor-section",
};
</script>
