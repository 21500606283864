<template>
  <div class="modifying mt-5 pt-5 text-center">
    <h2 class="title-blue h1">Storage Options</h2>
    <p class="my-5">
      The followings are some of the customized alternatives for you by our
      storage professionals.
    </p>
  </div>
</template>

<script>
export default {
  name: "fourth-stor-section",
};
</script>
