<template>
  <storage-component />
</template>

<script>
import StorageComponent from "../../components/solutions/data-center/Storage.vue";
export default {
  components: { StorageComponent },
  name: "storage-page",
};
</script>
