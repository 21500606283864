<template>
  <div class="modifying mt-5 pt-5 text-center">
    <h2 class="title-yellow h1">Why choose network-based storage?</h2>
    <p class="mt-2 mb-5">
      Networked Data Storage Lets You Consolidate Your Data in One Place.
    </p>
    <ul class="row">
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-lock fs-1 icon"></i>
        <h4 class="mt-5">Secure and reliable</h4>
        <p>Enterprise-brand hardware securely hosted in our data centers.</p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-dollar-sign fs-1 icon"></i>
        <h4 class="mt-5">Cost effective</h4>
        <p>
          A single investment in centralized storage, with unmetered bandwidth.
        </p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-sliders fs-1 icon"></i>
        <h4 class="mt-5">Centralized control</h4>
        <p>Centrally manage data security, access and storage hardware.</p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-database fs-1 icon"></i>
        <h4 class="mt-5">Secure data sharing</h4>
        <p>
          The productivity benefits of public cloud storage, with private
          security.
        </p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-share-from-square fs-1 icon"></i>
        <h4 class="mt-5">Consistent data</h4>
        <p>Share consolidated data between people, websites and databases</p>
      </li>
      <li class="col-lg-4 mt-5">
        <i class="fa-solid fa-sliders fs-1 icon"></i>
        <h4 class="mt-5">Simple management</h4>
        <p>
          That can allow companies to save time and even reduce the number of IT
          workers needed.
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "second-stor-section",
};
</script>
