<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">High Availability (HA) Cluster</h2>
      <h4 class="title-green">The Ultimate In High Availability</h4>
      <p class="desc">
        An HA Cluster is a combination of two or more of each service, like app,
        ﬁle and database servers. Traffic is disseminated by a load balancer
        that also guarantees failover in the occurrence of hardware or
        application loss.
      </p>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/data-center/storage/ha.jpg"
        alt="High Availability (HA) Cluster"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-stor-section",
};
</script>
